import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

const AdrenaleadLeadApp: React.FC = () => {
  const [montantConversion, setMontantConversion] = React.useState<number>(0)

  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search)
      const isClientParam = urlParams.get('is-client') === 'true'
      setMontantConversion(isClientParam ? 1 : 0)
    }
  }, [])

  return (
    <Helmet>
      <script type="text/javascript" defer={true}>
        {`
            var _nAdztr = _nAdztr || [];
            (function () {
              _nAdztr.push(["setAdvertiserId", "3140"]);
              _nAdztr.push(["setTransactionId", null]);
              _nAdztr.push(["setAmount", 0]);
              _nAdztr.push(["setTr"]);
              var u = "https://notifpush.com/scripts/";
              var s = document.createElement("script");
              s.type = "text/javascript";
              s.defer = true;
              s.async = true;
              s.src = u + "nadz-tr.js";
              var p = document.getElementsByTagName("script")[0];
              p.parentNode.insertBefore(s, p);
            })();
            if (window['NADZ_TRIGGERS'] != undefined) {
              var actions = window['NADZ_TRIGGERS'].getNadzTriggerActions();
              actions.handleActions([
                ['setProduitsDansPanier', false],
                ['setMontantConversion', ${montantConversion}]
              ]);
            } else {
              var _nAdzqTriggers = _nAdzqTriggers || [];
              _nAdzqTriggers.push(['handleActions', [
                ['setProduitsDansPanier', false],
                ['setMontantConversion', ${montantConversion}]
              ]]);
            }
          `}
      </script>
      <meta name="robots" content="noindex, nofollow"></meta>
    </Helmet>
  )
}

export default AdrenaleadLeadApp
